import { useState } from "react";
import { identityAPI } from '../../api/identityAPI';
import { toast } from 'react-toastify';
import AllPCode from '../../components/allPcode';
import Spinner from "../../components/spinner/spinner";

import LoginHeaderImage from '../../assets/images/main-logo.png'

import './login.css';

export default function LogIn({ setToken, setLogedUserName, setLoggedPcode }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [selectedPcode, setSelectedPcode] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSignIn = (e) => {
        e.preventDefault();

        //if (selectedPcode.trim().length === 0) { toast.warn("Please select a profit center"); return; } // Remove pcode selection from login, requested by Mr.Uthpala
        if (username.trim().length === 0) { toast.warn("Please enter user name"); return; }
        if (password.trim().length === 0) { toast.warn("Please enter password"); return; }

        setIsProcessing(true);

        identityAPI.logIn(username, password, selectedPcode).then((data) => {
            setToken(data.token);
            setLogedUserName(data.userName);
            setLoggedPcode(data.pcode)
        }).
            finally(() => {
                setPassword('');
                setIsProcessing(false);
            });
    };

    const clearForm = () => {
        setUsername('');
        setPassword('');
    }

    return (
        <div className="col __height ">
            <div className="row ccf  d-flex justify-content-center align-items-center">
                <div className="col-0 col-sm-0 col-md-3 col-lg-4 col-xl-4 col-xxl-4"></div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 loginCard rounded text-center">
                    {isProcessing === true && <Spinner />}
                    <form className={isProcessing === true && "visibility--hidden"}>
                        <div className="row p-4">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p-0 text-center">
                                <img src={LoginHeaderImage} alt="Your Image" className="img-fluid HeaderName w-25" />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-3">
                                <h5>THE CLASSIC CAR CLUB OF CEYLON</h5>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-3 bg-dark line">
                                <p className="text-dark m-0">.</p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-3 p-0">
                                <AllPCode setSelectedPcode={setSelectedPcode} />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-3 p-0">
                                <input value={username} onChange={(e) => setUsername(e.target.value)} type="text" className="form-control" placeholder="Username" />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-3 p-0">
                                <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="form-control" placeholder="Password" />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-4 p-0">
                                <button onClick={handleSignIn} type="submit" className="form-control btn btn-dark">Sign In</button>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-1 p-0">
                                <button type="reset" className="form-control btn btn-secondary" onClick={clearForm}>Clear</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-0 col-sm-0 col-md-3 col-lg-4 col-xl-4 col-xxl-4"></div>
            </div>
        </div>
    );
}