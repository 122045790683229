import { useEffect, useRef, useState } from "react";
import { modelAPI } from '../../api/modelAPI';

export default function VehicleModelSearch({ setModelCode, setModelName, triggerAfterSelected, vehRegCode }) {
    const isFirstRender = useRef(false);

    const [models, setModels] = useState([]);

    const getModels = (searchKey = '') => {
        modelAPI.getAllModelDetails(searchKey).then((data) => {
            setModels(data);
        })
    }

    const handleRowClick = (code, name) => {
        setModelCode(code);
        setModelName(name);

        if (triggerAfterSelected != null) {
            if (vehRegCode != null) { triggerAfterSelected(vehRegCode); }
            else { triggerAfterSelected(code); }
        }
    }

    useEffect(() => {
        if (!isFirstRender.current) {
            getModels.current = true;
            getModels();
        }
    }, [])

    return (
        <div className="modal fade" id="modalVehicleModelSearch">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Vehicle Model</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <div className="modal-body">
                        <input type="text" className="form-control" placeholder="Search by code or name"
                            onChange={(e) => getModels(e.target.value)} />
                        <table className="table mt-1">
                            <thead className="thead--color">
                                <tr>
                                    <th scope="col">Code</th>
                                    <th scope="col">Name</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {models && models.map(data => (
                                    <tr key={data.code}>
                                        <td>{data.code}</td>
                                        <td>{data.name}</td>
                                        <td className="text-center"><button type="button" className="btn btn-sm btn-dark"
                                            onClick={() => handleRowClick(data.code, data.name)} data-bs-dismiss="modal">
                                            Select</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}