import { api } from './configs/axiosConfig';

const endPoint = "/country";

export const countryAPI = {
    saveCountry: async function (code, name) {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'POST',
            data: { code, name }
        });
        return response.data;
    },
     getAllCountryDetails: async function (searchKey) {
         const response = await api.request({
             url: `${endPoint}/details`,
             method: 'GET',
             params: { searchKey }
         });

         return response.data;
     },
     getCountryDetails: async function (code) {
         const response = await api.request({
             url: `${endPoint}/${code}`,
             method: 'GET',
             params: { code }
         });

         return response.data;
     },
}
