import ChangePassword from "../../pages/utilities/changePassword/changePassword";
import UserManagement from "../../pages/utilities/userManagement";
import DBBackup from '../../pages/utilities/dbBackup';

export const utilitiesMenus = [
    {
        menuCode: 'U-DB',
        menuComponent: <DBBackup />
    },
    {
        menuCode: 'U-CP',
        menuComponent: <ChangePassword />
    },
    {
        menuCode: 'U-UM',
        menuComponent: <UserManagement />
    },
];