import MemberRegistration from '../../pages/transaction/memberRegistration';
import Notification from '../../pages/transaction/notification';
import MembershipRenewal from '../../pages/transaction/membershipRenewal';

export const transactionMenus = [
    {
        menuCode: 'T-MR',
        menuComponent: <MemberRegistration />
    },
    {
        menuCode: 'T-MRNEW',
        menuComponent: <MembershipRenewal />
    },
    {
        menuCode: 'T-NOTI',
        menuComponent: <Notification />
    },
];