import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { changePasswordAPI } from '../../../api/changePasswordAPI';

import Spinner from '../../../components/spinner';

export default function ChangePassword() {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");

    const [isProcessing, setIsProcessing] = useState(false);

    const savePassword = (e) => {
        e.preventDefault();

        if (currentPassword.trim().length === 0) { toast.warn('Please Enter the Current Password'); return; }
        if (newPassword.trim().length === 0) { toast.warn('Please Enter the new Password'); return; }
        if (newPassword.trim().length < 8 || newPassword.trim().length > 30)
        { toast.warn('Password must be 8 characters long'); return; }
        if (confirmPassword.trim().length === 0) { toast.warn('Please Enter the confirm Password'); return; }

        if (newPassword !== confirmPassword) { toast.warn('Passwords do not match'); return; }

        if (window.confirm("Do you want to save ?") === false) return;

        setIsProcessing(true);

        changePasswordAPI.savePassword(newPassword, currentPassword).then((data) => {
            toast.success("Successfully saved");
            handleClear();
        }).finally(() => setIsProcessing(false));
    }

    const handleClear = () => {
        setNewPassword('');
        setConfirmPassword('');
        setCurrentPassword('');
    }

    return (
        <div className="container">
            <div className='row'>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-4 col-xl-4 col-xxl-4'></div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 form__body'>
                    <h6 className='mb-4 text-center'>Change Password</h6>
                    {isProcessing ? <div className="text-center"><Spinner /></div> :
                        <form>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="password" className='h6'>Current Password</label>
                                        <input type="password" className="form-control" value={currentPassword} maxLength={30}
                                            onChange={(e) => setCurrentPassword(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="password" className='h6'>New Password</label>
                                        <input type="password" className="form-control" value={newPassword} maxLength={30}
                                            onChange={(e) => setNewPassword(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword" className='h6'>Confirm Password</label>
                                        <input type="password" className="form-control" value={confirmPassword} maxLength={30}
                                            onChange={(e) => setConfirmPassword(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-dark form-control mt-1" onClick={savePassword}>Save</button>
                                </div>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-secondary form-control mt-1" onClick={handleClear}>Clear</button>
                                </div>
                            </div>
                        </form>
                    }
                </div>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-4 col-xl-4 col-xxl-4'></div>
            </div>
        </div>
    )
}