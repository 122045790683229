import { LiaHomeSolid } from "react-icons/lia";
import { Routes, Route, Link } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";

import './home.css';
export default function Home() {
    

    return (
        <Link to="/mainmenu/mainMenu" className="home">
            <button type="button" className="btn btn-dark border border-0 d-flex align-items-center fs-3 p-2 pt-0 pb-0"><IoIosArrowBack /></button>
        </Link>
    );
}