import { api } from './configs/axiosConfig';

const endPoint = "/users";

export const usersAPI = {
    getPCentersUserWise: async function (userId) {
        const reponse = await api.request({
            url: `${endPoint}/pcenters`,
            method: 'GET'
        });

        return reponse.data;
    },
    getSystemMenusUserWise: async function() {
        const reponse = await api.request({
            url: `${endPoint}/systemmenus`,
            method: 'GET'
        });

        return reponse.data;
    },
    getUserManagementMenus: async function (user) {
        const reponse = await api.request({
            url: `${endPoint}/usermanagement-menus`,
            method: 'GET',
            params: { user }
        });

        return reponse.data;
    },
    saveUserPermission: async function (username, password, fullname, isInActive, menus) {
        const reponse = await api.request({
            url: `${endPoint}/user-permissions`,
            method: 'POST',
            data: { username, password, fullname, isInActive, menus }
        });

        return reponse.data;
    },
    getExistingUsers: async function () {
        const reponse = await api.request({
            url: `${endPoint}/existing-users`,
            method: 'GET',
        });

        return reponse.data;
    },
    deleteUser: async function (deleteUser) {
        const reponse = await api.request({
            url: `${endPoint}/delete-user`,
            method: 'POST',
            data: { deleteUser }
        });

        return reponse.data;
    }
}