import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { countryAPI } from '../../../api/countryAPI';

import Spinner from '../../../components/spinner';

export default function Country() {
    const didMount = useRef(false);
    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [countries, setCountries] = useState([]);

    const [isProcessing, setIsProcessing] = useState(false);

    const saveCountry = (e) => {
        e.preventDefault();

        if (code.trim().length === 0) { toast.warn('Please Enter the Code'); return; }
        if (name.trim().length === 0) { toast.warn('Please Enter the Country Name'); return; }
        if (window.confirm("Do you want to save ?") === false) return;

        setIsProcessing(true);

        countryAPI.saveCountry(code, name).then((data) => {
            toast.success("Successfully saved");
            handleClear();
            getAllCountries();
        }).finally(() => setIsProcessing(false));
    }

    const getAllCountries = (searchKey = '') => {
        countryAPI.getAllCountryDetails(searchKey).then((data) => {
            setCountries(data);
        })
    }

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            getAllCountries();
        }
    }, [])

    const handleClear = () => {
        setCode('');
        setName('');
    }

    const onInputCode = (code) => {
        setCode(code);
        setName('');
    }

    const onLeaveCode = (code) => {
        countryAPI.getCountryDetails(code).then((data) => {
            setName(data[0].name);
        })
    }

    const handleCountryRowClick = (code, name) => {
        setCode(code);
        setName(name);
    }

    return (
        <div className="container">
            <div className='row'>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 form__body'>
                    <h6 className='text-center'>Country</h6>
                    {isProcessing ? <div className="text-center"><Spinner /></div> :
                        <form>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="Code" className='h6'>Code</label>
                                        <input type="text" className="form-control" id="code" maxLength={5}
                                            value={code} onChange={(e) => onInputCode(e.target.value)}
                                            onBlur={(e) => onLeaveCode(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="Name" className='h6'>Name</label>
                                        <input type="text" className="form-control" id="memberName" maxLength={20}
                                            value={name} onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-dark form-control mt-1" onClick={saveCountry}>Save</button>
                                </div>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-secondary form-control mt-1" onClick={handleClear}>Clear</button>
                                </div>
                            </div>
                        </form>
                    }
                    <div className="row mt-3">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                            <input type="text" className="form-control" placeholder="Search by code or name"
                                onChange={(e) => getAllCountries(e.target.value)}
                            />
                            <div className="table-responsive table__height--1">
                                <table className="table mt-2">
                                    <thead className="thead--color">
                                        <tr>
                                            <th scope="col">Code</th>
                                            <th scope="col">Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {countries && countries.map(country => (
                                            <tr key={country.code} onClick={() => handleCountryRowClick(country.code, country.name)}>
                                                <td>{country.code}</td>
                                                <td>{country.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
            </div>
        </div>
    )
}


