 import React, { useEffect, useRef, useState } from 'react'
import AllPcode from '../../../components/userPcode';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { vehicalTypeAPI } from "../../../api/vehicalTypeAPI"

import Spinner from '../../../components/spinner';

export default function VehicalType() {
    const [vehical, setVehical] = useState([]);
    const [vCode, setVCode] = useState("");
    const [vehicalName, setVehicalName] = useState("");

    const isFirstRender = useRef(false);

    const [isProcessing, setIsProcessing] = useState(false);

    const saveVehical = (e) => {
        e.preventDefault();

        if (vCode.trim().length === 0) { toast.warn('Please Enter the Vehicle Code'); return; }
        if (vehicalName.trim().length === 0) { toast.warn('Please Enter the Vehicle Name'); return; }
        if (window.confirm("Do you want to save ?") === false) return;

        setIsProcessing(true);

        vehicalTypeAPI.saveVehical(vCode, vehicalName).then((data) => {
            toast.success("Successfully saved");
            handleClear();
            getVehical();
        }).finally(() => setIsProcessing(false));
    }

    const onLeaveVCode = (vCode) => {
        vehicalTypeAPI.getVehicalDetail(vCode).then((data) => {
            setVehicalName(data[0].vehicalName);
        })
    }

    const onInputVCode = (vCode) => {
        setVCode(vCode);
        setVehicalName('');
    }

    const handleClear = () => {
        setVCode('');
        setVehicalName('');

        getVehical();
    }

    const handleVehicalRowClick = (vCode, vName) => {
        setVCode(vCode);
        setVehicalName(vName);
    }

    const getVehical = (searchKey = '') => {
        vehicalTypeAPI.getAllVehicalDetails(searchKey).then((data) => {
            setVehical(data);
        })
    }

    useEffect(() => {
        if (!isFirstRender.current) {
            isFirstRender.current = true;
            getVehical()
        }
    }, [])

    return (
        <div className="container">
            <div className='row'>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 form__body'>
                    <h5 className='text-center'>Vehicle Type</h5>
                    {isProcessing ? <div className="text-center"><Spinner /></div> :
                        <form>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="vCode" className='h6'>Code</label>
                                        <input type="text" className="form-control" id="vCode" value={vCode} maxLength={5} onChange={(e) => onInputVCode(e.target.value)}
                                            onBlur={(e) => onLeaveVCode(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="vehicalName" className='h6'>Name</label>
                                        <input type="text" className="form-control" id="memberName" value={vehicalName} maxLength={20}
                                            onChange={(e) => setVehicalName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-dark form-control mt-1" onClick={saveVehical}>Save</button>
                                </div>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-secondary form-control mt-1" onClick={handleClear}>Clear</button>
                                </div>
                            </div>
                        </form>
                    }
                    <div className="row mt-3">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <input type="text" className="form-control" placeholder="Search by code or name" onChange={(e) => getVehical(e.target.value)} />
                            <div className="table-responsive table__height--1">
                                <table className="table mt-2">
                                    <thead className="thead--color">
                                        <tr>
                                            <th scope="col">Code</th>
                                            <th scope="col">Vehical Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {vehical && vehical.map(vehical => (
                                            <tr key={vehical.vCode} onClick={() => handleVehicalRowClick(vehical.vCode, vehical.vehicalName)}>
                                                <td>{vehical.vCode}</td>
                                                <td>{vehical.vehicalName}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
            </div>
        </div>
    )
}