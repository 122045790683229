import { useEffect, useState } from "react";
import { countryAPI } from '../../api/countryAPI';

export default function AllCountries({ setCountryCode, countryCode }) {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        countryAPI.getAllCountryDetails().then((data) => setCountries(data));
    }, [])

    const onChangeCountryCode = (e) => {
        setCountryCode(e.target.value);
    }

    return (
        <select className="form-select" onChange={onChangeCountryCode} value={countryCode}>
            <option value="">Select a country</option>
            {
                countries.map((data) => (
                    <option key={data.code} value={data.code}>{data.name}</option>
                ))
            }
        </select>
    );
}