import Brand from "../../pages/file/brand/brand";
import Country from "../../pages/file/country/country";
import MembershipCategory from "../../pages/file/membershipCategory/membershipCategory";
import VehicalType from "../../pages/file/vehicalType/vehicalType";
import Category from "../../pages/jobStatus";
import Model from '../../pages/file/model/model';

export const fileMenus = [
    {
        menuCode: 'F-MCAT',
        menuComponent: <MembershipCategory />
    },
    {
        menuCode: 'F-VT',
        menuComponent: <VehicalType />
    },
    {
        menuCode: 'F-BR',
        menuComponent: <Brand />
    },
    {
        menuCode: 'F-MD',
        menuComponent: <Model />
    },
    {
        menuCode: 'F-CO',
        menuComponent: <Country />
    },
];