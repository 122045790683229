import { api } from './configs/axiosConfig';

const endPoint = "/members";

export const memberAPI = {
    saveMemeberDetails: async function (cusCode, membershipTypeCode, selectedPcode, membershipNo, membershipDate, outstanding, comments,
        cusName, cusAdd, cusEmail, cusTel, cusMobile, paymentStatus) {
        const response = await api.request({
            url: `${endPoint}/member-details`,
            method: 'POST',
            data: {
                cusCode, membershipTypeCode, selectedPcode, membershipNo, membershipDate, outstanding, comments, cusName,
                cusAdd, cusEmail, cusTel, cusMobile, paymentStatus
            }
        });
        return response.data;
    },
    saveMemeberVehicle: async function (cusCode, vehRegCode, modelCode, dateManufacture, countryManufature, engineNo, chassisNo, plateNo,
        vehicleRemarks, pcode, vehicleDateRegistration) {
        const response = await api.request({
            url: `${endPoint}/member-vehicles`,
            method: 'POST',
            data: {
                cusCode, vehRegCode, modelCode, dateManufacture, countryManufature, engineNo, chassisNo, plateNo,
                vehicleRemarks, pcode, vehicleDateRegistration
            }
        });
        return response.data;
    },
    getAllMemberDetails: async function (searchKey,pcode) {
        const response = await api.request({
            url: `${endPoint}/details`,
            method: 'GET',
            params: { searchKey, pcode }
        });

        return response.data;
    },
    getMemberDetails: async function (cusCode,pcode) {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'GET',
            params: { cusCode, pcode }
        });

        return response.data;
    },
    getVehicleDetails: async function (cusCode, vehRegCode, pcode) {
        const response = await api.request({
            url: `${endPoint}/vehicles`,
            method: 'GET',
            params: { cusCode, vehRegCode, pcode }
        });

        return response.data;
    },
    generateMemberDetailReport: async function (pcode, membershipTypeCode, membershipTypeName, vehicleTypeCode, vehicleTypeName,
        vehicleModelCode, vehicleModelName, paymentStatus, isWithoutVehicleDetails, filteration, membershipYear) {
        const response = await api.request({
            url: `${endPoint}/member-details-report`,
            method: 'GET',
            params: {
                pcode, membershipTypeCode, membershipTypeName, vehicleTypeCode, vehicleTypeName, vehicleModelCode, vehicleModelName,
                paymentStatus, isWithoutVehicleDetails, filteration, membershipYear
            }
        });

        return response.data;
    },
}