import { api } from './configs/axiosConfig';

const endPoint = "/document-type";

export const documentTypeAPI = {
    getAllDocumentType: async function () {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'GET'
        });

        return response.data;
    }
}
