import { useEffect, useRef, useState } from "react";
import { memberAPI } from '../../api/memberAPI';

export default function MemberSearch({ setCusCode, triggerAfterSelected, pcode }) {
    const isFirstRender = useRef(false);

    const [members, setMembers] = useState([]);

    const getMembers = (searchKey = '') => {
        memberAPI.getAllMemberDetails(searchKey, pcode).then((data) => {
            setMembers(data);
        })
    }

    const handleRowClick = (code) => {
        setCusCode(code);

        if (triggerAfterSelected != null) { triggerAfterSelected(code); }
    }

    useEffect(() => {
        if (!isFirstRender.current) {
            isFirstRender.current = true;
            getMembers()
        }
    }, [])

    return (
        <div class="modal fade" id="modalMemberSearch">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Members</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <div class="modal-body">
                        <input type="text" className="form-control" placeholder="Search by code or name"
                            onChange={(e) => getMembers(e.target.value)} />
                        <table className="table mt-1">
                            <thead className="thead--color">
                                <tr>
                                    <th scope="col">Code</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Membership #</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {members && members.map(data => (
                                    <tr key={data.cusCode}>
                                        <td>{data.cusCode}</td>
                                        <td>{data.cusName}</td>
                                        <td>{data.membershipNo}</td>
                                        <td className="text-center"><button type="button" className="btn btn-sm btn-dark"
                                            onClick={() => handleRowClick(data.cusCode)} data-bs-dismiss="modal">
                                            Select</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}