import { api } from './configs/axiosConfig';

const endPoint = "/brand";

export const brandAPI = {
    saveBrand: async function (code, name) {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'POST',
            data: { code, name }
        });
        return response.data;
    },
    getAllBrandDetails: async function (searchKey) {
        const response = await api.request({
            url: `${endPoint}/details`,
            method: 'GET',
            params: {searchKey }
        });

        return response.data;
    },
    getBrandDetails: async function (code) {
        const response = await api.request({
            url: `${endPoint}/${code}`,
            method: 'GET',
            params: { code }
        });

        return response.data;
    },
}
