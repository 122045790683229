import React, { useEffect, useState } from 'react'
import { notificationAPI } from '../../../api/notificationAPI';
import AllPcode from '../../../components/userPcode';

import Spinner from '../../../components/spinner';

import MembershipTypeSearch from '../../../components/membershipTypeSearch';
import VehicleTypeSearch from '../../../components/vehicleTypeSearch';
import VehicleBrandSearch from '../../../components/vehicleBrandSearch';
import AllCountries from '../../../components/allCountries';

import { toast } from 'react-toastify';

import { common } from '../../../common/common';

import './notification.css';

export default function Notification() {
    const [selectedPcode, setSelectedPcode] = useState('');
    const [membershipTypeCode, setMembershipTypeCode] = useState('');
    const [membershipTypeName, setMembershipTypeName] = useState('');
    const [vehicleTypeCode, setVehicleTypeCode] = useState('');
    const [vehicleTypeName, setVehicleTypeName] = useState('');
    const [vehicleMakeCode, setVehicleMakeCode] = useState('');
    const [vehicleMakeName, setVehicleMakeName] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [message, setMessage] = useState('');

    const [membershipYear, setMembershipYear] = useState('');
    const [manufactureYear, setManufactureYear] = useState('');

    const [allMemCatChecked, setAllMemCatChecked] = useState(true);
    const [allVehTypeChecked, setAllVehTypeChecked] = useState(true);
    const [allVehMakeChecked, setAllVehMakeChecked] = useState(true);

    const [isProcessing, setIsProcessing] = useState(false);

    const [members, setMembers] = useState([]);

    const onChangeAllMemberCat = (e) => {
        if (e.target.checked) {
            setMembershipTypeCode('');
            setMembershipTypeName('');

            setAllMemCatChecked(true);
        }
        else {
            setAllMemCatChecked(false);
        }
    }

    const onChangeAllVehType = (e) => {
        if (e.target.checked) {
            setVehicleTypeCode('');
            setVehicleTypeName('');

            setAllVehTypeChecked(true);
        }
        else {
            setAllVehTypeChecked(false);
        }
    }

    const onChangeAllVehMake = (e) => {
        if (e.target.checked) {
            setVehicleMakeCode('');
            setVehicleMakeName('');

            setAllVehMakeChecked(true);
        }
        else {
            setAllVehMakeChecked(false);
        }
    }

    useEffect(() => {
        if (membershipTypeCode.length !== 0) {
            setAllMemCatChecked(false);
        }

        if (vehicleTypeCode.length !== 0) {
            setAllVehTypeChecked(false);
        }

        if (vehicleMakeCode.length !== 0) {
            setAllVehMakeChecked(false);
        }
    }, [membershipTypeCode, vehicleTypeCode, vehicleMakeCode])

    const resetForm = () => {
        setAllMemCatChecked(true);
        setAllVehTypeChecked(true);
        setAllVehMakeChecked(true);

        setMembershipTypeCode('');
        setMembershipTypeName('');
        setVehicleTypeCode('');
        setVehicleTypeName('');
        setVehicleMakeCode('');
        setVehicleMakeName('');
        setCountryCode('');

        setMembershipYear('');
        setManufactureYear('');

        setNotificationType('');
        setMessage('');
        setMembers([]);
    }

    const loadMembers = () => {
        if (notificationType.trim().length === 0) { toast.warn("Please select a notification type"); return; }

        if (notificationType === "membershipDue" && membershipYear.length === 0) { toast.warn("Please enter membership year"); return; }

        setIsProcessing(true);

        notificationAPI.getMembers(notificationType, membershipTypeCode, vehicleTypeCode, vehicleMakeCode, countryCode,
            manufactureYear, membershipYear, selectedPcode).then((data) => setMembers(data)).
            finally(() => setIsProcessing(false));
    }

    const onClickMemberAction = (cusCode, membershipNo, cusName, cusMobile, action) => {
        setMembers(prevArray => {
            const newArray = [...prevArray];

            for (let row = 0; row < newArray.length; row++) {
                if (newArray[row].cusCode === cusCode) {
                    newArray[row].action = action.toUpperCase() === "YES" ? "No" : "Yes";
                }
            }

            return newArray;
        });   
    }

    const sendNotification = () => {
        if (notificationType.trim().length === 0)
        { toast.warn("Please select a notification type"); return; }

        if (members.length === 0) { toast.warn("Please select member/s"); return; }

        if (message.trim().length === 0) { toast.warn("Please enter a message"); return; }

        if (notificationType === "membershipDue" && membershipYear.length === 0) { toast.warn("Please enter membership year"); return; }

        if (window.confirm("Do you want to send ?") === false) return;

        setIsProcessing(true);

        notificationAPI.sendNotification(selectedPcode, members, notificationType, message).then((data) => {
            toast.success(data.emailResponse);
            toast.success(data.smsResponseMessage);
        }).finally(() => setIsProcessing(false));
    }

    return (
        <div className="container">
            {
                selectedPcode &&
                <MembershipTypeSearch setMembershipTypeCode={setMembershipTypeCode} setMembershipTypeName={setMembershipTypeName}
                    pcode={selectedPcode} />
            }
            <VehicleTypeSearch setVehicleTypCode={setVehicleTypeCode} setVehicleTypeName={setVehicleTypeName} />
            <VehicleBrandSearch setBrandCode={setVehicleMakeCode} setBrandName={setVehicleMakeName} />
            <div className='row'>
                <div className='col-xs-0 col-sm-0 col-md-1'></div>
                <div className='col-xs-12 col-sm-12 col-md-10 form__body'>
                    <h6 className='text-center'>Notification</h6>
                    {isProcessing ? <div className="text-center"><Spinner /></div> :
                        <form>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="usr" className='h6'>Center</label>
                                        <AllPcode setSelectedPcode={setSelectedPcode} triggerOnChangePCode={resetForm} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-4 ps-md-0">
                                    <div className="form-group">
                                        <label htmlFor="usr" className='h6'>Notification Type</label>
                                        <select className="form-select" onChange={(e) => setNotificationType(e.target.value)}
                                            value={notificationType}>
                                            <option value="">Select notification type</option>
                                            <option value="membershipDue">Membership Due</option>
                                            <option value="event">Event</option>
                                            <option value="general">General</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <label htmlFor="usr" className='h6'>Membership Category</label>
                                <div className="col-8 col-sm-8 col-md-3 pe-0 me-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={membershipTypeCode} readOnly />
                                    </div>
                                </div>
                                <div className="col-4 col-sm-4 col-md-1 text-center px-0">
                                    <button type="button" className="btn btn-sm btn-secondary m-0 px-sm-1" data-bs-toggle="modal"
                                        data-bs-target="#modalMembershipypeSearch"><span className="small">Find</span></button>
                                </div>
                                <div className="col-9 col-sm-10 col-md-4 ps-md-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={membershipTypeName} readOnly />
                                    </div>
                                </div>
                                <div className="col-3 col-sm-2 col-md-1 p-0">
                                    <div className="form-group">
                                        <input type="checkbox" onChange={(e) => onChangeAllMemberCat(e)} checked={allMemCatChecked} /> All
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <label htmlFor="usr" className='h6'>Vehicle Type</label>
                                <div className="col-8 col-sm-8 col-md-3 pe-0 me-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={vehicleTypeCode} readOnly />
                                    </div>
                                </div>
                                <div className="col-4 col-sm-4 col-md-1 text-center px-0">
                                    <button type="button" className="btn btn-sm btn-secondary m-0 px-sm-1" data-bs-toggle="modal"
                                        data-bs-target="#modalVehicleTypeSearch"><span className="small">Find</span></button>
                                </div>
                                <div className="col-9 col-sm-10 col-md-4 ps-md-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={vehicleTypeName} readOnly />
                                    </div>
                                </div>
                                <div className="col-3 col-sm-2 col-md-1 p-0">
                                    <div className="form-group">
                                        <input type="checkbox" checked={allVehTypeChecked} onChange={(e) => onChangeAllVehType(e)} /> All
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <label htmlFor="usr" className='h6'>Make</label>
                                <div className="col-8 col-sm-8 col-md-3 pe-0 me-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={vehicleMakeCode} readOnly />
                                    </div>
                                </div>
                                <div className="col-4 col-sm-4 col-md-1 text-center px-0">
                                    <button type="button" className="btn btn-sm btn-secondary m-0 px-sm-1" data-bs-toggle="modal"
                                        data-bs-target="#modalVehicleBrandSearch"><span className="small">Find</span></button>
                                </div>
                                <div className="col-9 col-sm-10 col-md-4 ps-md-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={vehicleMakeName} readOnly />
                                    </div>
                                </div>
                                <div className="col-3 col-sm-2 col-md-1 p-0">
                                    <div className="form-group">
                                        <input type="checkbox" checked={allVehMakeChecked} onChange={(e) => onChangeAllVehMake(e)} /> All
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-3">
                                    <label htmlFor="usr" className='h6'>Country</label>
                                    <div className="form-group">
                                        <AllCountries setCountryCode={setCountryCode} countryCode={countryCode} />
                                    </div>
                                </div>
                                <div className="col-0 col-sm-0 col-md-1"></div>
                                <div className="col-12 col-sm-12 col-md-2 ps-md-0">
                                    <label htmlFor="usr" className='h6'>Year of Manufacture</label>
                                    <div className="form-group">
                                        <input type="number" className="form-control text-end" value={manufactureYear}
                                            onChange={(e) => setManufactureYear(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-2">
                                    <label htmlFor="usr" className='h6'>Membership Year</label>
                                    <div className="form-group">
                                        <input type="number" className="form-control text-end"
                                            disabled={notificationType === "membershipDue" ? false : true}
                                            value={membershipYear} onChange={(e) => setMembershipYear(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-1">
                                    <button type="button" className="btn btn-dark btn-sm mt-4" onClick={loadMembers}>Load</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-12 col-md-8'>
                                    <label className='h6'>Message</label>
                                    <div className="form-group">
                                        <textarea className="form-control" maxLength="250" rows="3" value={message}
                                            onChange={(e) => setMessage(e.target.value)}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-12 table-responsive table__members">
                                    <table className="table table-striped mt-1">
                                        <thead className="thead--color">
                                            <tr className="p-0 m-0">
                                                <th scope="col" className="p-0 m-0">Membership #</th>
                                                <th scope="col" className="p-0 m-0">Name</th>
                                                <th scope="col" className="p-0 m-0">Mobile #</th>
                                                <th scope="col" className="p-0 m-0">Email</th>
                                                <th className="text-center p-0 m-0">Send Notification</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                members && members.map((data) => (
                                                    <tr className="p-0 m-0">
                                                        <td className="p-0 m-0">{data.membershipNo}</td>
                                                        <td className="p-0 m-0">{data.cusName}</td>
                                                        <td className="p-0 m-0">{data.cusMobile}</td>
                                                        <td className="p-0 m-0">{data.cusEmail}</td>
                                                        <td className="text-center p-0 m-0">
                                                            <button className={"btn btn-sm " + (data.action === "Yes" ? "btn-success" : "btn-danger") + " p-1 pt-0 pb-0"} type="button" onClick={() =>
                                                                onClickMemberAction(data.cusCode, data.membershipNo,
                                                                    data.cusName, data.cusMobile, data.action)}>{data.action}</button>
                                                            </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6'></div>
                                <div className='col col-sm-12 col-md-3'>
                                    <button type="button" className="btn btn-dark form-control mt-1" onClick={sendNotification}>Send</button>
                                </div>
                                <div className='col col-sm-12 col-md-3'>
                                    <button type="button" className="btn btn-secondary form-control mt-1" onClick={resetForm}>Clear</button>
                                </div>
                            </div>
                        </form>
                    }
                </div>
                <div className='col-xs-0 col-sm-0 col-md-1'></div>
            </div>
        </div>
    )
}