import React, { useEffect, useRef, useState } from 'react'
import AllPcode from '../../../components/userPcode';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { membershipCategoryAPI } from '../../../api/membershipCategoryAPI';

import Spinner from '../../../components/spinner';

export default function MembershipCategory() {
    const [members, setMembers] = useState([]);
    const [selectedPcode, setSelectedPcode] = useState('');
    const [mCode, setMCode] = useState("");
    const [memberName, setMemberName] = useState("");

    const isFirstRender = useRef(true);
    const [isProcessing, setIsProcessing] = useState(false);

    const saveMember = (e) => {
        e.preventDefault();

        if (selectedPcode.trim().length === 0) { toast.warn('Please Select a Center'); return; }
        if (mCode.trim().length === 0) { toast.warn('Please Enter the Member Code'); return; }
        if (memberName.trim().length === 0) { toast.warn('Please Enter the Member Name'); return; }
        if (window.confirm("Do you want to save ?") === false) return;

        setIsProcessing(true);
        membershipCategoryAPI.saveMember(selectedPcode, mCode, memberName).then((data) => {
            toast.success("Successfully saved");
            handleClear();
            getMembers();
        }).finally(() => setIsProcessing(false));
    }

    const onLeaveMCode = (mCode) => {
        membershipCategoryAPI.getMemberDetail(mCode, selectedPcode).then((data) => {
            setMemberName(data[0].memberName);
        })
    }

    const onInputMCode = (mCode) => {
        setMCode(mCode);
        setMemberName('');
    }

    const handleClear = () => {
        setMCode('');
        setMemberName('');

        getMembers();
    }

    const handleMemberRowClick = (mCode, mName) => {
        setMCode(mCode);
        setMemberName(mName);
    }

    const getMembers = (searchKey = '') => {
        membershipCategoryAPI.getAllMemberDetails(selectedPcode, searchKey).then((data) => {

            setMembers(data);
        })
    }

    useEffect(() => {
        getMembers();
    }, [selectedPcode])

    return (
        <div className="container">
            <div className='row'>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 form__body'>
                    <h6 className='text-center'>Membership Category</h6>
                    {isProcessing ? <div className="text-center"><Spinner /></div> :
                        <form>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="usr" className='h6'>Center</label>
                                        <AllPcode setSelectedPcode={setSelectedPcode} triggerOnChangePCode={handleClear} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="mCode" className='h6'>Code</label>
                                        <input type="text" className="form-control" id="mCode" value={mCode} maxLength={8} onChange={(e) => onInputMCode(e.target.value)}
                                            onBlur={(e) => onLeaveMCode(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="memberName" className='h6'>Name</label>
                                        <input type="text" className="form-control" id="memberName" value={memberName} maxLength={20}
                                            onChange={(e) => setMemberName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-dark form-control mt-1" onClick={saveMember}>Save</button>
                                </div>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-secondary form-control mt-1" onClick={handleClear}>Clear</button>
                                </div>
                            </div>
                        </form>
                    }
                    {selectedPcode && (
                        <div className="row mt-3">
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <input type="text" className="form-control" placeholder="Search by code or name" onChange={(e) => getMembers(e.target.value)} />
                                <div className="table-responsive table__height--1">
                                    <table className="table mt-2">
                                        <thead className="thead--color">
                                            <tr>
                                                <th scope="col">Code</th>
                                                <th scope="col">Member Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {members && members.map(member => (
                                                <tr key={member.mCode} onClick={() => handleMemberRowClick(member.mCode, member.memberName)}>
                                                    <td>{member.mCode}</td>
                                                    <td>{member.memberName}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
            </div>
        </div>
    )
}