import { api } from './configs/axiosConfig';

const endPoint = "/dbBackup";

export const dbBackupAPI = {
    dbBackup: async function () {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'GET',
            responseType: 'blob'
        });

        return response.data;
    }
}