export const common = {
    convertToDate: function (dateTime) {
        let date = new Date(dateTime);

        let dMonth = date.getMonth() + 1;

        let month = dMonth < 10 ? '0' + dMonth : dMonth;
        let day = date.getDay() < 10 ? '0' + date.getDay() : date.getDay();

        return `${date.getFullYear()}-${month}-${day}`;
    },
    convertToDateTime: function (dateTime) {
        let date = new Date(dateTime);

        return `${date.getFullYear()}/${date.getMonth()+1}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}`;
    },
    convertToMonthDay: function (dateTime) {
        let date = new Date(dateTime);
        
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        return `${month[date.getMonth()]}/${date.getDate()}`;
    },
    convertToTime: function (dateTime) {
        let date = new Date(dateTime);

        return `${date.getHours()}:${date.getMinutes()}`;
    },
    getRDLCPDFLinkFromBase64: function (sBASE64ReportData) {
        let ArrayBuffer = common.convertBase64ToArrayBuffer(sBASE64ReportData)
        let blob = new Blob([ArrayBuffer], { type: "application/pdf" });
        let link = window.URL.createObjectURL(blob);

        return link;
    },
    convertBase64ToArrayBuffer: function (base64) {
        let binaryString = window.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    },
    openBlobAsPDF: function (sBASE64ReportData) {
        window.open(common.getRDLCPDFLinkFromBase64(sBASE64ReportData), '_blank');
    },

}