import { useEffect, useRef, useState } from "react";
import { brandAPI } from '../../api/brandAPI';

export default function VehicleBrandSearch({ setBrandCode, setBrandName }) {
    const isFirstRender = useRef(false);

    const [brands, setBrands] = useState([]);

    const getBrands = (searchKey = '') => {
        brandAPI.getAllBrandDetails(searchKey).then((data) => {
            setBrands(data);
        })
    }

    const handleRowClick = (code, name) => {
        setBrandCode(code);
        setBrandName(name);
    }

    useEffect(() => {
        if (!isFirstRender.current) {
            isFirstRender.current = true;
            getBrands();
        }
    }, [])

    return (
        <div class="modal fade" id="modalVehicleBrandSearch">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Make</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <div class="modal-body">
                        <input type="text" className="form-control" placeholder="Search by code or name"
                            onChange={(e) => getBrands(e.target.value)} />
                        <table className="table mt-1">
                            <thead className="thead--color">
                                <tr>
                                    <th scope="col">Code</th>
                                    <th scope="col">Name</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {brands && brands.map(vehical => (
                                    <tr key={vehical.code}>
                                        <td>{vehical.code}</td>
                                        <td>{vehical.name}</td>
                                        <td className="text-center"><button type="button" className="btn btn-sm btn-dark"
                                            onClick={() => handleRowClick(vehical.code, vehical.name)} data-bs-dismiss="modal">
                                            Select</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}