import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify';
import { modelAPI } from '../../../api/modelAPI';

import VehicleTypeSearch from '../../../components/vehicleTypeSearch';
import VehicleBrandSearch from '../../../components/vehicleBrandSearch';

import Spinner from '../../../components/spinner';

export default function Model() {
    const didMount = useRef(false);

    const [vCode, setSelectedVCode] = useState('');
    const [vName, setSelectedVName] = useState('');
    const [brandCode, setBrandCode] = useState("");
    const [brandName, setBrandName] = useState("");
    const [modelCode, setModelCode] = useState("");
    const [mDescription, setMDescription] = useState("");

    const [models, setModels] = useState([]);

    const [isProcessing, setIsProcessing] = useState(false);

    const saveModel = (e) => {
        e.preventDefault();

        if (vCode.trim().length === 0) { toast.warn('Please Select a Vehicle Type'); return; }
        if (modelCode.trim().length === 0) { toast.warn('Please Enter the Model Code'); return; }
        if (brandCode.trim().length === 0) { toast.warn('Please Enter the Brand Code'); return; }
        if (mDescription.trim().length === 0) { toast.warn('Please Enter the Model Description'); return; }

        if (window.confirm("Do you want to save ?") === false) return;

        setIsProcessing(true);

        modelAPI.saveModel(vCode, brandCode, modelCode, mDescription).then((data) => {
            toast.success("Successfully saved");
            handleClear();
            getAllModels();
        }).finally(() => setIsProcessing(false));
    }

    const handleClear = () => {
        setModelCode('');
        setMDescription('');
    }

    const resetForm = () => {
        setSelectedVCode('');
        setSelectedVName('');
        setBrandCode('');
        setBrandName('');
        setModelCode('');
        setMDescription('');
    }

    const getAllModels = (searchKey = '') => {
        modelAPI.getAllModelDetails(searchKey).then((data) => {
            setModels(data);
        })
    }

    const getModelsDetails = (code) => {
        if (code.trim().length === 0) { return; }

        modelAPI.getModelDetails(code).then((data) => {
            setSelectedVCode(data[0].VehTypeCode);
            setSelectedVName(data[0].VehTypeName);
            setBrandCode(data[0].BrandCode);
            setBrandName(data[0].BrandName);
            setMDescription(data[0].name);
        })
    }

    const handleRowClick = (code, name) => {
        setModelCode(code);
        getModelsDetails(code);
    }

    const onInputModelCode = () => {
        setMDescription('');
    }

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            getAllModels();
        }
    }, [])

    return (
        <div className="container">
            <div className='row mt-1'>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 form__body'>
                    <h4 className='text-center'>Model</h4>
                    {isProcessing ? <div className="text-center"><Spinner /></div> :
                        <form>
                            <div className="row">
                                <label htmlFor="usr" className='h6'>Vehicle Type</label>
                                <div className="col-8 col-sm-8 col-md-4 pe-0 me-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={vCode} readOnly />
                                    </div>
                                </div>
                                <div className="col-4 col-sm-4 col-md-1 text-center px-0">
                                    <button type="button" className="btn btn-sm btn-secondary m-0 px-sm-1" data-bs-toggle="modal"
                                        data-bs-target="#modalVehicleTypeSearch"><span className="small">Find</span></button>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-7 ps-md-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={vName} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <label htmlFor="memberName" className='h6'>Brand</label>
                                <div className="col-8 col-sm-8 col-md-4 pe-0 me-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={brandCode} readOnly />
                                    </div>
                                </div>
                                <div className="col-4 col-sm-4 col-md-1 text-center px-0">
                                    <button type="button" className="btn btn-sm btn-secondary m-0 px-sm-1" data-bs-toggle="modal"
                                        data-bs-target="#modalVehicleBrandSearch"><span className="small">Find</span></button>
                                </div>
                                <div className="col-12 col-sm-12 col-md-7 ps-md-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={brandName} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-xs-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="mCode" className='h6'>Model Code</label>
                                        <input type="text" className="form-control" id="mCode"
                                            value={modelCode}
                                            onChange={(e) => setModelCode(e.target.value)}
                                            onBlur={(e) => getModelsDetails(e.target.value)}
                                            onInput={() => onInputModelCode()}
                                            maxLength="5"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="memberName" className='h6'>Model Description</label>
                                        <input type="text" className="form-control" id="memberName"
                                            value={mDescription}
                                            onChange={(e) => setMDescription(e.target.value)}
                                            maxLength="20"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-dark form-control mt-1" onClick={saveModel}>Save</button>
                                </div>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-secondary form-control mt-1" onClick={resetForm}>Clear</button>
                                </div>
                            </div>
                        </form>
                    }
                    <div className="row mt-3">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <input type="text" className="form-control" placeholder="Search by code or name"
                                onChange={(e) => getAllModels(e.target.value)}
                            />
                            <div className="table-responsive table__height--1">
                                <table className="table mt-2">
                                    <thead className="thead--color">
                                        <tr>
                                            <th scope="col">Code</th>
                                            <th scope="col">Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {models && models.map(member => (
                                                <tr key={member.code} onClick={() => handleRowClick(member.code, member.name)}>
                                                <td>{member.code}</td>
                                                    <td>{member.name}</td>
                                                </tr>
                                            ))} 
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
            </div>
            <VehicleTypeSearch setVehicleTypCode={setSelectedVCode} setVehicleTypeName={setSelectedVName} />
            <VehicleBrandSearch setBrandCode={setBrandCode} setBrandName={setBrandName} />
        </div>
    )
}