import { api } from './configs/axiosConfig';

const endPoint = "/password";

export const changePasswordAPI = {
    savePassword: async function (newPassword, currentPassword) {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'POST',
            data: { newPassword, currentPassword }
        });
        return response.data;
    },
}