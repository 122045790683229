import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify';
import { modelAPI } from '../../../api/modelAPI';
import { memberAPI } from '../../../api/memberAPI';
import AllPcode from '../../../components/userPcode';

import Spinner from '../../../components/spinner';

import MembershipTypeSearch from '../../../components/membershipTypeSearch';
import VehicleTypeSearch from '../../../components/vehicleTypeSearch';
import VehicleModelSearch from '../../../components/vehicleModelSearch';

import { common } from '../../../common/common';

import { membersipRenewalAPI } from '../../../api/membershipRenewalAPI';

export default function MemberDetails() {
    const [selectedPcode, setSelectedPcode] = useState('');
    const [membershipTypeCode, setMembershipTypeCode] = useState('');
    const [membershipTypeName, setMembershipTypeName] = useState('');
    const [vehicleTypeCode, setVehicleTypeCode] = useState('');
    const [vehicleTypeName, setVehicleTypeName] = useState('');
    const [vehicleModelCode, setVehicleModelCode] = useState('');
    const [vehicleModelName, setVehicleModelName] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');

    const [allMemCatChecked, setAllMemCatChecked] = useState(true);
    const [allVehTypeChecked, setAllVehTypeChecked] = useState(true);
    const [allVehModelChecked, setAllVehModelChecked] = useState(true);

    const [isProcessing, setIsProcessing] = useState(false);

    const [isWithoutVehicleDetails, setIsWithoutVehicleDetails] = useState(false);

    const [filteration, setFilteration] = useState('renewed');
    const [membershipYear, setMembershipYear] = useState('');

    const onChangeAllMemberCat = (e) => {
        if (e.target.checked) {
            setMembershipTypeCode('');
            setMembershipTypeName('');

            setAllMemCatChecked(true);
        }
        else {
            setAllMemCatChecked(false);
        }
    }

    const onChangeAllVehType = (e) => {
        if (e.target.checked) {
            setVehicleTypeCode('');
            setVehicleTypeName('');

            setAllVehTypeChecked(true);
        }
        else {
            setAllVehTypeChecked(false);
        }
    }

    const onChangeAllVehModel = (e) => {
        if (e.target.checked) {
            setVehicleModelCode('');
            setVehicleModelName('');

            setAllVehModelChecked(true);
        }
        else {
            setAllVehModelChecked(false);
        }
    }

    useEffect(() => {
        if (membershipTypeCode.length !== 0)
        {
            setAllMemCatChecked(false);
        }

        if (vehicleTypeCode.length !== 0) {
            setAllVehTypeChecked(false);
        }

        if (vehicleModelCode.length !== 0) {
            setAllVehModelChecked(false);
        }
    }, [membershipTypeCode, vehicleTypeCode, vehicleModelCode])

    const resetForm = () => {
        setAllMemCatChecked(true);
        setAllVehTypeChecked(true);
        setAllVehModelChecked(true);

        setMembershipTypeCode('');
        setMembershipTypeName('');
        setVehicleTypeCode('');
        setVehicleTypeName('');
        setVehicleModelCode('');
        setVehicleModelName('');

        setPaymentStatus('');
        setFilteration('all');
    }

    const peviewReport = () => {
        setIsProcessing(true);

        memberAPI.generateMemberDetailReport(selectedPcode, membershipTypeCode, membershipTypeName, vehicleTypeCode, vehicleTypeName,
            vehicleModelCode, vehicleModelName, paymentStatus, isWithoutVehicleDetails, filteration, membershipYear).
            then((data) => { common.openBlobAsPDF(data) }).
            finally(() => setIsProcessing(false));
    }

    const getModelsDetails = (modelCode) => {
        modelAPI.getModelDetails(modelCode).then((data) => {
            setVehicleTypeCode(data[0].VehTypeCode);
            setVehicleTypeName(data[0].VehTypeName);
        })
    }

    const getMembershipYear = () => {
        membersipRenewalAPI.getCurrentMembershipYear(selectedPcode).then((data) => setMembershipYear(data));
    }

    useEffect(() => {
        getMembershipYear();
    }, [selectedPcode])

    return (
        <div className="container">
            {
                selectedPcode &&
                <MembershipTypeSearch setMembershipTypeCode={setMembershipTypeCode} setMembershipTypeName={setMembershipTypeName}
                    pcode={selectedPcode} />
            }
            <VehicleTypeSearch setVehicleTypCode={setVehicleTypeCode} setVehicleTypeName={setVehicleTypeName} />
            <VehicleModelSearch setModelCode={setVehicleModelCode} setModelName={setVehicleModelName} triggerAfterSelected={getModelsDetails} />
            <div className='row'>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 form__body'>
                    <h6 className='text-center'>Member Details</h6>
                    {isProcessing ? <div className="text-center"><Spinner /></div> :
                        <form>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="usr" className='h6'>Center</label>
                                        <AllPcode setSelectedPcode={setSelectedPcode} triggerOnChangePCode={resetForm} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <label htmlFor="usr" className='h6'>Membership Category</label>
                                <div className="col-8 col-sm-8 col-md-4 pe-0 me-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={membershipTypeCode} readOnly />
                                    </div>
                                </div>
                                <div className="col-4 col-sm-4 col-md-1 text-center px-0">
                                    <button type="button" className="btn btn-sm btn-secondary m-0 px-sm-1" data-bs-toggle="modal"
                                        data-bs-target="#modalMembershipypeSearch"><span className="small">Find</span></button>
                                </div>
                                <div className="col-9 col-sm-10 col-md-6 ps-md-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={membershipTypeName} readOnly />
                                    </div>
                                </div>
                                <div className="col-3 col-sm-2 col-md-1 p-0">
                                    <div className="form-group">
                                        <input type="checkbox" onChange={(e) => onChangeAllMemberCat(e)} checked={allMemCatChecked} /> All
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <label htmlFor="usr" className='h6'>Vehicle Type</label>
                                <div className="col-8 col-sm-8 col-md-4 pe-0 me-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={vehicleTypeCode} readOnly />
                                    </div>
                                </div>
                                <div className="col-4 col-sm-4 col-md-1 text-center px-0">
                                    <button type="button" className="btn btn-sm btn-secondary m-0 px-sm-1" data-bs-toggle="modal"
                                        data-bs-target="#modalVehicleTypeSearch"><span className="small">Find</span></button>
                                </div>
                                <div className="col-9 col-sm-10 col-md-6 ps-md-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={vehicleTypeName} readOnly />
                                    </div>
                                </div>
                                <div className="col-3 col-sm-2 col-md-1 p-0">
                                    <div className="form-group">
                                        <input type="checkbox" checked={allVehTypeChecked} onChange={(e) => onChangeAllVehType(e)} /> All
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <label htmlFor="usr" className='h6'>Vehicle Model</label>
                                <div className="col-8 col-sm-8 col-md-4 pe-0 me-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={vehicleModelCode} readOnly />
                                    </div>
                                </div>
                                <div className="col-4 col-sm-4 col-md-1 text-center px-0">
                                    <button type="button" className="btn btn-sm btn-secondary m-0 px-sm-1" data-bs-toggle="modal"
                                        data-bs-target="#modalVehicleModelSearch"><span className="small">Find</span></button>
                                </div>
                                <div className="col-9 col-sm-10 col-md-6 ps-md-0">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            value={vehicleModelName} readOnly />
                                    </div>
                                </div>
                                <div className="col-3 col-sm-2 col-md-1 p-0">
                                    <div className="form-group">
                                        <input type="checkbox" checked={allVehModelChecked} onChange={(e) => onChangeAllVehModel(e)} /> All
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-12 col-md-12">
                                    <div className="form-group">
                                        <input type="radio" value="all"
                                            checked={filteration === "all"} onChange={(e) => setFilteration(e.target.value)} /> All
                                        <input type="radio" className="ms-5" value="renewed" checked={filteration === "renewed"}
                                            onChange={(e) => setFilteration(e.target.value)} /> Membership Renewed
                                        <input type="radio" className="ms-5" value="not-renewd" checked={filteration === "not-renewd"}
                                            onChange={(e) => setFilteration(e.target.value)} /> Membership Not-Renewed
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 mt-3">
                                    <div className="form-group">
                                        <input type="checkbox" value={isWithoutVehicleDetails}
                                            onChange={(e) => setIsWithoutVehicleDetails(e.target.checked)}
                                            defaultChecked={isWithoutVehicleDetails} /> Without Vehicle Details
                                    </div>
                                </div>
                            </div>
                            {
                                filteration !== "all" &&
                                <div className="row mt-3" >
                                    <div className="col-12 col-md-4">
                                        <label htmlFor="usr" className='h6'>Membership Year</label>
                                        <div className="form-group">
                                            <input type="number" className="form-control text-end" value={membershipYear}
                                                onChange={(e) => setMembershipYear(e.target.value)} />
                                        </div>
                                    </div>
                                    {
                                        filteration === "renewed" &&
                                        <div className="col-12 col-md-8">
                                            <label htmlFor="usr" className='h6'>Payment Status</label>
                                            <div className="form-group">
                                                <select className="form-select text-danger" onChange={(e) => setPaymentStatus(e.target.value)}
                                                    value={paymentStatus}>
                                                    <option value="">All</option>
                                                    <option value="paid">Paid</option>
                                                    <option value="unpaid">Unpaid</option>
                                                </select>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            <div className='row'>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-dark form-control mt-1" onClick={peviewReport}>Preview</button>
                                </div>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-secondary form-control mt-1" onClick={resetForm}>Clear</button>
                                </div>
                            </div>
                        </form>
                    }
                </div>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
            </div>
        </div>
    )
}