import { api } from './configs/axiosConfig';

const endPoint = "/member";

export const membershipCategoryAPI = {
    saveMember: async function (pCode, mCode, memberName) {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'POST',
            data: { pCode, mCode, memberName }
        });
        return response.data;
    },

    getAllMemberDetails: async function (pCode, searchKey) {
        const response = await api.request({
            url: `${endPoint}/details`,
            method: 'GET',
            params: { pCode,searchKey }
        });
        
        return response.data;
    },
    getMemberDetail: async function (mCode, pCode) {
        const response = await api.request({
            url: `${endPoint}/${mCode}`,
            method: 'GET',
            params: { pCode }
        });
        
        return response.data;
    },

}
