import { useEffect, useState } from "react";
import { documentTypeAPI } from '../../api/documentType';

export default function DocumentTypes({ setDocumentTypeCode, documentTypeCode }) {
    const [documentTypes, setDocumentTypes] = useState([]);

    useEffect(() => {
        documentTypeAPI.getAllDocumentType().then((data) => setDocumentTypes(data));
    }, [])

    const onChangeDocumentType = (e) => {
        setDocumentTypeCode(e.target.value);
    }

    return (
        <select className="form-select" onChange={onChangeDocumentType} value={documentTypeCode}>
            <option value="">Select a document Type</option>
            {
                documentTypes.map((data) => (
                    <option key={data.Code} value={data.Code}>{data.Name}</option>
                ))
            }
        </select>
    );
}