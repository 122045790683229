import { api } from './configs/axiosConfig';

const endPoint = "/vehical";

export const vehicalTypeAPI = {
    saveVehical: async function (vCode, vehicalName) {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'POST',
            data: { vCode, vehicalName }
        });
        return response.data;
    },

    getAllVehicalDetails: async function (searchKey) {
        
        const response = await api.request({
            url: `${endPoint}/details`,
            method: 'GET',
            params: {  searchKey }
        });

        return response.data;
    },
    getVehicalDetail: async function (vCode) {
        const response = await api.request({
            url: `${endPoint}/${vCode}`,
            method: 'GET',
            
        });

        return response.data;
    },

}