import { useEffect, useRef, useState } from "react";
import { vehicalTypeAPI } from '../../api/vehicalTypeAPI';

export default function VehicleTypeSearch({ setVehicleTypCode, setVehicleTypeName }) {
    const isFirstRender = useRef(false);

    const [vehicles, SetVehicles] = useState([]);

    const getVehical = (searchKey = '') => {
        vehicalTypeAPI.getAllVehicalDetails(searchKey).then((data) => {
            SetVehicles(data);
        })
    }

    const handleVehicalRowClick = (code, name) => {
        setVehicleTypCode(code);
        setVehicleTypeName(name);
    }

    useEffect(() => {
        if (!isFirstRender.current) {
            isFirstRender.current = true;
            getVehical()
        }
    }, [])

    return (
        <div class="modal fade" id="modalVehicleTypeSearch">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Vehicle Type</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <div class="modal-body">
                        <input type="text" className="form-control" placeholder="Search by code or name"
                            onChange={(e) => getVehical(e.target.value)} />
                        <table className="table mt-1">
                            <thead className="thead--color">
                                <tr>
                                    <th scope="col">Code</th>
                                    <th scope="col">Name</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicles && vehicles.map(vehical => (
                                    <tr key={vehical.vCode}>
                                        <td>{vehical.vCode}</td>
                                        <td>{vehical.vehicalName}</td>
                                        <td className="text-center"><button type="button" className="btn btn-sm btn-dark"
                                            onClick={() => handleVehicalRowClick(vehical.vCode, vehical.vehicalName)} data-bs-dismiss="modal">
                                            Select</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}