import React, { useEffect, useRef, useState } from 'react'
import { dbBackupAPI } from '../../../api/dbBackupAPI';
import { toast } from 'react-toastify';

import Spinner from '../../../components/spinner';

export default function DBBackup() {

    const [isProcessing, setIsProcessing] = useState(false);

    const backup = () => {
        if (window.confirm("Do you want to continue ?") === false) return;

        setIsProcessing(true);

        dbBackupAPI.dbBackup().then((data) => {
                // download file
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "Database.bak");
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
        }).catch((data) => {
            toast.warn("Something went wrong, try again");
        }).finally(() => setIsProcessing(false));
    }

    return (
        <div className="container">
            <div className='row'>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 form__body'>
                    <h5 className='text-center'>Database Backup</h5>
                    {isProcessing ? <div className="text-center"><Spinner /></div> :
                        <form>
                            <div className='row'>
                                <div className="col-md-3"></div>
                                <div className='col-12 col-md-6 text-center'>
                                    <button type="button" className="btn btn-dark form-control mt-1" onClick={backup}>Backup</button>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                        </form>
                    }
                </div>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
            </div>
        </div>
    )
}