import { api } from './configs/axiosConfig';

const endPoint = "/model";

export const modelAPI = {
    saveModel: async function (vCode, brandCode, modelCode, mDescription) {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'POST',
            data: { vCode, brandCode, modelCode, mDescription }
        });
        return response.data;
    },
    getAllModelDetails: async function (searchKey) {
        const response = await api.request({
            url: `${endPoint}/details`,
            method: 'GET',
            params: { searchKey }
        });

        return response.data;
    },
    getModelDetails: async function (code) {
        const response = await api.request({
            url: `${endPoint}/${code}`,
            method: 'GET',
            params: { code }
        });

        return response.data;
    }
}
