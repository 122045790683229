import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { usersAPI } from '../../../api/usersAPI';

import Spinner from '../../../components/spinner';

export default function UserManagement() {
    const didMount = useRef(false);

    const [userName, setUserName] = useState('');
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');

    const [isInActive, setIsInActive] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const [menus, setMenus] = useState([]);
    const [existingUsers, setExistingUsers] = useState([]);

    const [isAddAllPermission, setIsAddAllPermission] = useState(false);
    const [isRemoveAllPermission, setIsRemoveAllPermission] = useState(false);

    useEffect(() => {
        if (!didMount.current) {
            getExistingUsers();
        }
    }, []);

    const getExistingUsers = () => {
        usersAPI.getExistingUsers().then((data) => setExistingUsers(data));
    }

    const loadMenus = (user) => {

        setPassword('');
        setConfirmPassword('');
        setFullName('');
        setIsInActive(false);
        setMenus([]);
        setIsRemoveAllPermission(false);
        setIsAddAllPermission(false)

        setIsProcessing(true);
        usersAPI.getUserManagementMenus(user).then((data) => {
            setMenus(data.menus);
            setFullName(data.userDetails.fullName);
            setIsInActive(!data.userDetails.active);
        }).finally(() => setIsProcessing(false));
    }

    const changeUserPermission = (subMenuCode, isAllowed) => {
        setMenus(prevArray => {
            const newArray = [...prevArray];

            for (let row = 0; row < newArray.length; row++) {
                if (newArray[row].subMenuCode === subMenuCode) {
                    newArray[row].isAllowed = isAllowed;
                }
            }

            return newArray;
        });   
    }

    const saveUser = () => {
        if (userName.trim().length === 0) { toast.warn("Please enter user name"); return; }
        if (menus.length === 0) { toast.warn("Please enter user permissions"); return; }

        if (password.trim().length !== 0 && password.trim().length < 8) { toast.warn("Password must be atleast 8 characters long"); return; }
        if (password !== confirmpassword) { toast.warn("Passwords do not match"); return; }

        if (window.confirm("Do you want to save ?") === false) return;

        setIsProcessing(true);

        usersAPI.saveUserPermission(userName, password, fullName, isInActive, menus).
            then(() => {
                toast.success("Successfully saved.");
                resetForm();
            }).
            finally(() => setIsProcessing(false));
    }

    const resetForm = () => {
        setMenus([]);
        setUserName('');
        setPassword('');
        setConfirmPassword('');
        setFullName('');
        setIsInActive(false);

        getExistingUsers();

        setIsRemoveAllPermission(false);
        setIsAddAllPermission(false)
    }

    const onClickUser = (user) => {
        setUserName(user);
        loadMenus(user);
    }

    const deleteUser = () => {
        if (userName.trim().length === 0) { toast.warn("Please enter user name"); return; }

        if (window.confirm("Do you want to save ?") === false) return;

        setIsProcessing(true);

        usersAPI.deleteUser(userName).
            then(() => {
                toast.success("Successfully Removed.");
                resetForm();
            }).
            finally(() => setIsProcessing(false));
    }

    const addAllPermission = (isAddAll) => {

        setIsAddAllPermission(isAddAll);
        if (isAddAll) { setIsRemoveAllPermission(!isAddAll) }

        if (isAddAll) {
            setMenus(prevArray => {
                const newArray = [...prevArray];

                for (let row = 0; row < newArray.length; row++) {
                    newArray[row].isAllowed = true;
                }

                return newArray;
            });   
        }
    }

    const removeAllPermission = (isRemoveAll) => {

        setIsRemoveAllPermission(isRemoveAll);
        if (isRemoveAll) { setIsAddAllPermission(!isRemoveAll) }

        if (isRemoveAll) {
            setMenus(prevArray => {
                const newArray = [...prevArray];

                for (let row = 0; row < newArray.length; row++) {
                    newArray[row].isAllowed = false;
                }

                return newArray;
            });
        }
    }

    return (
        <div className="container">
            <div className='row'>
                <div className='col-xs-0 col-sm-0 col-md-1'></div>
                <div className='col-xs-12 col-sm-12 col-md-10 form__body'>
                    <h6 className='text-center'>User Management</h6>
                    {isProcessing ? <div className="text-center"><Spinner /></div> :
                        <form>
                            <div className="row">
                                <label htmlFor="Code" className='h6'>Username</label>
                                <div className="col-xs-8 col-md-3">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="code" maxLength={8}
                                            value={userName} onChange={(e) => setUserName(e.target.value)}
                                            onBlur={(e) => loadMenus(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-8 col-md-4">
                                    <div className="form-group">
                                        <input type="checkbox" checked={isInActive} defaultChecked={isInActive}
                                            onChange={(e) => setIsInActive(e.target.checked)} /> In-Active
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="Code" className='h6'>Full Name</label>
                                        <input type="text" className="form-control" id="code" maxLength={30}
                                            value={fullName} onChange={(e) => setFullName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="Code" className='h6'>Password</label>
                                        <input type="password" className="form-control" id="code" maxLength={8}
                                            value={password} onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="Code" className='h6'>Confirm Password</label>
                                        <input type="password" className="form-control" id="code" maxLength={8}
                                            value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    {
                                        userName.length !== 0 &&
                                        <>
                                            <input type="checkbox" checked={isAddAllPermission} defaultChecked={isAddAllPermission}
                                                onChange={(e) => addAllPermission(e.target.checked)} /> Add All
                                            <input type="checkbox" className="ms-5" checked={isRemoveAllPermission} defaultChecked={setIsRemoveAllPermission}
                                                onChange={(e) => removeAllPermission(e.target.checked)}
                                            /> Remove All
                                        </>
                                    }
                                    <div className="row mt-1">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            <div className="dropdown">
                                                <button type="button" className="btn btn-light text-start form-control dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#fileMenu" aria-expanded="false" aria-controls="fileMenu">
                                                    File
                                                </button>
                                                <div class="collapse mt-2" id="fileMenu">
                                                    <div class="card card-body card__menu">
                                                        {
                                                            menus && menus.filter((item) => item.mainMenu === "File").map((data) => (
                                                                <div className="row"  key={data.subMenuCode}>
                                                                    <div className="col-6">{data.subMenuName}</div>
                                                                    <div className="col-6">
                                                                        <input type="checkbox" className="ms-5"
                                                                            onChange={(e) => changeUserPermission(data.subMenuCode, e.target.checked)}
                                                                            checked={data.isAllowed} defaultChecked={data.isAllowed} /> Allow</div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            <div className="dropdown">
                                                <button type="button" className="btn btn-light text-start form-control dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#tranMenu" aria-expanded="false" aria-controls="tranMenu">
                                                    Member Management
                                                </button>
                                                <div class="collapse mt-2" id="tranMenu">
                                                    <div class="card card-body card__menu">
                                                        {
                                                            menus && menus.filter((item) => item.mainMenu === "Transaction").map((data) => (
                                                                <div className="row" key={data.subMenuCode}>
                                                                    <div className="col-6">{data.subMenuName}</div>
                                                                    <div className="col-6">
                                                                        <input type="checkbox" className="ms-5"
                                                                            onChange={(e) => changeUserPermission(data.subMenuCode, e.target.checked)}
                                                                            checked={data.isAllowed} defaultChecked={data.isAllowed} /> Allow</div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            <div className="dropdown">
                                                <button type="button" className="btn btn-light text-start form-control dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#reportMenu" aria-expanded="false" aria-controls="reportMenu">
                                                    Reports
                                                </button>
                                                <div class="collapse mt-2" id="reportMenu">
                                                    <div class="card card-body card__menu">
                                                        {
                                                            menus && menus.filter((item) => item.mainMenu === "Report").map((data) => (
                                                                <div className="row" key={data.subMenuCode}>
                                                                    <div className="col-6">{data.subMenuName}</div>
                                                                    <div className="col-6">
                                                                        <input type="checkbox" className="ms-5"
                                                                            onChange={(e) => changeUserPermission(data.subMenuCode, e.target.checked)}
                                                                            checked={data.isAllowed} defaultChecked={data.isAllowed} /> Allow</div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            <div className="dropdown">
                                                <button type="button" className="btn btn-light text-start form-control dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#utilityMenu" aria-expanded="false" aria-controls="utilityMenu">
                                                    Utilities
                                                </button>
                                                <div class="collapse mt-2" id="utilityMenu">
                                                    <div class="card card-body card__menu">
                                                        {
                                                            menus && menus.filter((item) => item.mainMenu === "Utilities").map((data) => (
                                                                <div className="row" key={data.subMenuCode}>
                                                                    <div className="col-6">{data.subMenuName}</div>
                                                                    <div className="col-6">
                                                                        <input type="checkbox" className="ms-5"
                                                                            onChange={(e) => changeUserPermission(data.subMenuCode, e.target.checked)}
                                                                            checked={data.isAllowed} defaultChecked={data.isAllowed} /> Allow</div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col col-sm-12 col-md-12 col-lg-4'>
                                            <button type="button" className="btn btn-dark form-control mt-1" onClick={saveUser}>Save</button>
                                        </div>
                                        <div className='col col-sm-12 col-md-12 col-lg-4'>
                                            <button type="button" className="btn btn-danger form-control mt-1" onClick={deleteUser}>Delete</button>
                                        </div>
                                        <div className='col col-sm-12 col-md-12 col-lg-4'>
                                            <button type="button" className="btn btn-secondary form-control mt-1" onClick={resetForm}>Clear</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <p className="fw-bold mb-0">Exisiting Users</p>
                                    <table className="table mt-0">
                                        <thead className="thead--color">
                                            <tr>
                                                <th scope="col">Username</th>
                                                <th scope="col">Full Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                existingUsers && existingUsers.map((data) => (
                                                    <tr onClick={() => onClickUser(data.user_Name)}>
                                                        <td>{data.user_Name}</td>
                                                        <td>{data.fullName}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form>
                    }
                </div>
                <div className='col-xs-0 col-sm-0 col-md-1'></div>
            </div>
        </div>
    )
}