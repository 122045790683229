import { useEffect, useRef, useState } from "react";
import { membershipCategoryAPI } from '../../api/membershipCategoryAPI';

export default function MembershipTypeSearch({ setMembershipTypeCode, setMembershipTypeName, pcode }) {
    const isFirstRender = useRef(false);

    const [membershipTypes, setMembershipTypes] = useState([]);

    const getMembershipTypes = (searchKey = '') => {
        membershipCategoryAPI.getAllMemberDetails(pcode, searchKey).then((data) => {
            setMembershipTypes(data);
        })
    }

    const handleowClick = (code, name) => {
        setMembershipTypeCode(code);
        setMembershipTypeName(name);
    }

    useEffect(() => {
        if (!isFirstRender.current) {
            isFirstRender.current = true;
            getMembershipTypes()
        }
    }, [])

    return (
        <div className="modal fade" id="modalMembershipypeSearch">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Membership Category</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <div className="modal-body">
                        <input type="text" className="form-control" placeholder="Search by code or name"
                            onChange={(e) => getMembershipTypes(e.target.value)} />
                        <table className="table mt-1">
                            <thead className="thead--color">
                                <tr>
                                    <th scope="col">Code</th>
                                    <th scope="col">Name</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {membershipTypes && membershipTypes.map(data => (
                                    <tr key={data.mCode}>
                                        <td>{data.mCode}</td>
                                        <td>{data.memberName}</td>
                                        <td className="text-center"><button type="button" className="btn btn-sm btn-dark"
                                            onClick={() => handleowClick(data.mCode, data.memberName)} data-bs-dismiss="modal">
                                            Select</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}