import { Link } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { useEffect, useState } from "react";

import './navigation.css';

import { fileMenus as fileMenusComponent } from '../../data/systemMenu/fileMenu';
import { transactionMenus as transactionMenusComponent } from '../../data/systemMenu/transactionMenu';
import { reportMenus as reportMenusComponent } from '../../data/systemMenu/reportMenu';
import { utilitiesMenus as utilitiesMenusComponent } from '../../data/systemMenu/utilitiesMenu';

import { usersAPI } from '../../api/usersAPI';

export default function Navigation({ logOut }) {
    const [fileMenu, setFileMenu] = useState([]);
    const [tranMenu, setTranMenu] = useState([]);
    const [reportMenu, setReportMenu] = useState([]);
    const [utilitiesMenu, setUtilitiesMenu] = useState([]);

    const getSystemMenu = () => {
        usersAPI.getSystemMenusUserWise().
            then((data) => {
                setFileMenu(data.filter((item) => item.mainMenu === "File"));
                setTranMenu(data.filter((item) => item.mainMenu === "Transaction"));
                setReportMenu(data.filter((item) => item.mainMenu === "Report"));
                setUtilitiesMenu(data.filter((item) => item.mainMenu === "Utilities"));
            });
    }

    const renderRoute = (subMenu, component) => {
        const allowedComponent = component.filter((data) => data.menuCode === subMenu.subMenuCode);

        if (allowedComponent.length === 1)
            return (<Route key={subMenu.subMenuCode} path={subMenu.subMenuRoutePath} element={allowedComponent[0].menuComponent}></Route>);
    }

    useEffect(() => {
        getSystemMenu();
    },[])

    const NavBar = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-1 col-sm-1 col-md-3 col-lg-3 col-xl-3 col-xxl-3"></div>
                        <div className="col-10 col-sm-10 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="row ">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center text-white">
                                    <h4>THE CLASSIC CAR CLUB OF CEYLON</h4>
                                </div>
                            </div>
 
                            <div className="row mt-1">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="dropdown">
                                        <button type="button" className="btn btn-dark form-control dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#fileMenu" aria-expanded="false" aria-controls="fileMenu">
                                            File
                                        </button>
                                        <div class="collapse mt-2" id="fileMenu">
                                            <div class="card card-body card__menu">
                                            {
                                                fileMenu[0] && fileMenu[0].subMenuGrouped.map((grouped, groupedIndex) => (
                                                    <div key={groupedIndex}>
                                                        {
                                                            grouped.subMenu.map((subMenu) => (
                                                                <p>
                                                                    <Link to={subMenu.subMenuLinkPath} className="dropdown-item card__menu--item">
                                                                        {subMenu.subMenuName}
                                                                    </Link>
                                                                </p>
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="dropdown">
                                        <button type="button" className="btn btn-dark dropdown-toggle form-control" data-bs-toggle="collapse" data-bs-target="#tranMenu" aria-expanded="false" aria-controls="tranMenu">
                                            Member Management
                                        </button>
                                        <div class="collapse mt-2" id="tranMenu">
                                            <div class="card card-body card__menu">
                                            {
                                                tranMenu[0] && tranMenu[0].subMenuGrouped.map((grouped, groupedIndex) => (
                                                    <div key={groupedIndex}>
                                                        {
                                                            grouped.subMenu.map((subMenu) => (
                                                                <p>
                                                                    <Link to={subMenu.subMenuLinkPath} className="dropdown-item card__menu--item">
                                                                        {subMenu.subMenuName}
                                                                    </Link>
                                                                </p>
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="dropdown">
                                        <button type="button" className="btn btn-dark dropdown-toggle form-control" data-bs-toggle="collapse" data-bs-target="#reportMenu" aria-expanded="false" aria-controls="reportMenu">
                                            Reports
                                        </button>
                                        <div class="collapse mt-2" id="reportMenu">
                                            <div class="card card-body card__menu">
                                            {
                                                reportMenu[0] && reportMenu[0].subMenuGrouped.map((grouped, groupedIndex) => (
                                                    <div key={groupedIndex}>
                                                        {
                                                            grouped.subMenu.map((subMenu) => (
                                                                <p>
                                                                    <Link to={subMenu.subMenuLinkPath} className="dropdown-item card__menu--item">
                                                                        {subMenu.subMenuName}
                                                                    </Link>
                                                                </p>
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="dropdown">
                                        <button type="button" className="btn btn-dark dropdown-toggle form-control" data-bs-toggle="collapse" data-bs-target="#utilityMenu" aria-expanded="false" aria-controls="utilityMenu">
                                            Utilities
                                        </button>
                                        <div class="collapse mt-2" id="utilityMenu">
                                            <div class="card card-body card__menu">
                                            {
                                                utilitiesMenu[0] && utilitiesMenu[0].subMenuGrouped.map((grouped, groupedIndex) => (
                                                    <div key={groupedIndex}>
                                                        {
                                                            grouped.subMenu.map((subMenu) => (
                                                                <p>
                                                                    <Link to={subMenu.subMenuLinkPath} className="dropdown-item card__menu--item">
                                                                        {subMenu.subMenuName}
                                                                    </Link>
                                                                </p>
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <button type="button" className="form-control btn btn-secondary" onClick={logOut}>Sign Out</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 col-sm-1 col-md-3 col-lg-3 col-xl-3 col-xxl-3"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Routes>
                <Route path={"*"} element={<NavBar />}></Route>
                {
                    fileMenu[0] && fileMenu[0].subMenuGrouped.map((grouped) => (
                        grouped.subMenu.map((subMenu) => (renderRoute(subMenu, fileMenusComponent)))
                    ))
                }
                {
                    tranMenu[0] && tranMenu[0].subMenuGrouped.map((grouped) => (
                        grouped.subMenu.map((subMenu) => (renderRoute(subMenu, transactionMenusComponent)))
                    ))
                }
                {
                    reportMenu[0] && reportMenu[0].subMenuGrouped.map((grouped) => (
                        grouped.subMenu.map((subMenu) => (renderRoute(subMenu, reportMenusComponent)))
                    ))
                }
                {
                    utilitiesMenu[0] && utilitiesMenu[0].subMenuGrouped.map((grouped) => (
                        grouped.subMenu.map((subMenu) => (renderRoute(subMenu, utilitiesMenusComponent)))
                    ))
                }
            </Routes>
        </>
    );
}