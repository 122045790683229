import { api } from './configs/axiosConfig';

const endPoint = "/membership-renewal";

export const membersipRenewalAPI = {
    getMembers: async function (membershipTypeCode, vehicleTypeCode, vehicleMakeCode, countryCode,
        yearManufacture, membershipYear, pcode) {
        const reponse = await api.request({
            url: `${endPoint}/members`,
            method: 'GET',
            params: {
                membershipTypeCode, vehicleTypeCode, vehicleMakeCode, countryCode,
                yearManufacture, membershipYear, pcode
            }
        });

        return reponse.data;
    },
    saveRenewal: async function (pcode, members, membershipYear) {
        const reponse = await api.request({
            url: `${endPoint}/save-renewal`,
            method: 'POST',
            data: {
                pcode, members, membershipYear
            }
        });

        return reponse.data;
    },
    getCurrentMembershipYear: async function (pcode) {
        const reponse = await api.request({
            url: `${endPoint}/current-membership-year`,
            method: 'GET',
            params: {
                pcode
            }
        });

        return reponse.data;
    }
}