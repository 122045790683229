import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify';
import { brandAPI } from '../../../api/brandAPI';

import Spinner from '../../../components/spinner';

export default function Brand() {
    const didMount = useRef(false);
    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [brands, setBrands] = useState([]);

    const [isProcessing, setIsProcessing] = useState(false);

    const saveBrand = (e) => {
        e.preventDefault();

        if (code.trim().length === 0) { toast.warn('Please Enter the Code'); return; }
        if (name.trim().length === 0) { toast.warn('Please Enter the Brand Name'); return; }
        if (window.confirm("Do you want to save ?") === false) return;

        setIsProcessing(true);

        brandAPI.saveBrand(code, name).then((data) => {
            toast.success("Successfully saved");
            handleClear();
            getAllBrands();
        }).finally(() => setIsProcessing(false));
    }

    const getAllBrands = (searchKey = '') => {
        brandAPI.getAllBrandDetails(searchKey).then((data) => {
            setBrands(data);
        })
    }

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            getAllBrands();
        }
    }, [])

    const handleClear = () => {
        setCode('');
        setName('');
    }

    const handleBrandRowClick = (code, name) => {
        setCode(code);
        setName(name);
    }

    const onInputCode = (code) => {
        setCode(code);
        setName('');
    }

    const onLeaveCode = (code) => {
        brandAPI.getBrandDetails(code).then((data) => {
            setName(data[0].name);
        })
    }

    return (
        <div className="container">
            <div className='row'>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 form__body'>
                    <h6 className='text-center'>Make</h6>
                    {isProcessing ? <div className="text-center"><Spinner /></div> :
                        <form>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="mCode" className='h6'>Code</label>
                                        <input type="text" className="form-control" id="code" maxLength={5}
                                            value={code} onChange={(e) => onInputCode(e.target.value)}
                                            onBlur={(e) => onLeaveCode(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="memberName" className='h6'>Name</label>
                                        <input type="text" className="form-control" id="memberName" maxLength={20}
                                            value={name} onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-dark form-control mt-1" onClick={saveBrand}>Save</button>
                                </div>
                                <div className='col col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                                    <button type="button" className="btn btn-secondary form-control mt-1" onClick={handleClear}>Clear</button>
                                </div>
                            </div>
                        </form>
                    }
                    <div className="row mt-3">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <input type="text" className="form-control" placeholder="Search by code or name"
                                onChange={(e) => getAllBrands(e.target.value)}
                            />
                            <div className="table-responsive table__height--1">
                                <table className="table mt-2">
                                    <thead className="thead--color">
                                        <tr>
                                            <th scope="col">Code</th>
                                            <th scope="col">Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {brands && brands.map(brand => (
                                            <tr key={brand.code} onClick={() => handleBrandRowClick(brand.code, brand.name)}>
                                                <td>{brand.code}</td>
                                                <td>{brand.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
            </div>
        </div>
    )
}