import { api } from './configs/axiosConfig';

const endPoint = "/notification";

export const notificationAPI = {
    getMembers: async function (notificationType, membershipTypeCode, vehicleTypeCode, vehicleMakeCode, countryCode,
        yearManufacture, membershipYear, pcode) {
        const reponse = await api.request({
            url: `${endPoint}/members`,
            method: 'GET',
            params: {
                notificationType, membershipTypeCode, vehicleTypeCode, vehicleMakeCode, countryCode,
                yearManufacture, membershipYear, pcode
            }
        });

        return reponse.data;
    },
    sendNotification: async function (pcode, members, notificationType, message) {
        const reponse = await api.request({
            url: `${endPoint}/send-notification`,
            method: 'POST',
            data: {
                pcode, members, notificationType, message
            }
        });

        return reponse.data;
    }
}